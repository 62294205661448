import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { addCommentUserStory, addTasksComment } from "./addCommentUserStory";
import { logEntry } from "../../../Common/logEntry";

export async function getActiveUserStory(activities,getUser, story_id, moduleId, handleClose, handleOpen, message, project_name = null, meeting_url, sprint_or_kanban_success_msg = null) {
  try {
    const start_time=Date.now();
    var response = await API.post(
      "manage_userstories.php",
      {
        crop: getUser.corp,
        story_id: story_id,
        empId: getUser.empId,
        action: "activate_user_story",
      },
      {},
      false
    );
    const end_time=Date.now();
    if (response.status === "true") {
      Alert("success", message);
      addCommentUserStory(getUser, story_id, message, "1");
      activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
        sub_activity:"In Progress",
        response_time : (end_time-start_time), task_meta_data:{story_id:story_id}

      })
      handleClose();
      if (sprint_or_kanban_success_msg) {
        Alert("success", sprint_or_kanban_success_msg);
      }
      if (meeting_url !== null && meeting_url !== undefined) {
        window.open(meeting_url, '_blank');
      }

    } else if (response.status === "true1") {
      handleClose();
      const data = { inprogressStoryId: response.data, todoStoryId: story_id, currentSprint: moduleId, assignedTo: response.assignedTo, projectName: response.project_name, story_title: response.story_title, inprogress_story_title: response.inprogress_story_title, us_id: response.us_id, todo_us_id: response.todo_us_id, toDoProjectName: project_name, task_type: 'main_task', inprogress_task_type: response.inprogress_task_type, meetingUrl: meeting_url }
      handleOpen("changeUserstoryStatus", '0', data); //For change the UserStory Status

    } else {
      Alert("warning", response.message);

    }
  } catch (error) {
    console.log(error);
      //error log
      activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
        sub_activity:"In Progress",
        error_message:error.message
      })
  }
}
export async function getActiveSubUserStory(getUser, task_id, moduleId, handleClose, handleOpen, message, project_name = null, main_task_id, story_id, project_id, us_id, priority) {
  var response1 = await API.post(
    "get_tasks.php",
    {
      crop: getUser.corp,
      mainTaskId: story_id,
      empId: getUser.empId,
      projectId: project_id,
      action: "getsubtasks",
    },
    {},
    false
  );
  if (response1.status === "true") {
    if (response1.data.length > 0) {
      const pendingSubtasks = response1.data.filter((list) => (list.status === 'pending' && list.activeStatus !== "-1"));
      const taskPosition_id = pendingSubtasks && pendingSubtasks.length > 0 ? pendingSubtasks[0].t_id : '';
      const existingSubTask = pendingSubtasks && pendingSubtasks.length > 0 ? pendingSubtasks : '';
      if (taskPosition_id === us_id) {
        try {
          var response = await API.post(
            "manage_userstories.php",
            {
              crop: getUser.corp,
              story_id: task_id,
              empId: getUser.empId,
              action: "activate_sub_user_story",
            },
            {},
            false
          );
          if (response.status === "true") {
            Alert("success", message);
            addTasksComment(getUser, task_id, "1", message);
            handleClose();

          } else if (response.status === "true1") {
            // handleClose();
            const data = { inprogressStoryId: response.data, todoStoryId: task_id, currentSprint: moduleId, assignedTo: response.assignedTo, projectName: response.project_name, story_title: response.story_title, inprogress_story_title: response.inprogress_story_title, us_id: response.us_id, todo_us_id: `${main_task_id}-${response.todo_us_id}`, toDoProjectName: project_name, task_type: 'sub_task', inprogress_task_type: response.inprogress_task_type }
            handleOpen("changeUserstoryStatus", '0', data); //For change the UserStory Status

          } else {
            Alert("warning", response.message);
            handleClose();
          }
        } catch (error) {
          // console.log(error);
        }
      } else {
        handleClose();
        Alert("warning", `You cannot start this subtask assignment order ${priority}, until you finish ${main_task_id}-${taskPosition_id}-${existingSubTask[0]?.taskTitle} assignment order ${existingSubTask[0]?.priorityLevel}.`);
      }
    }
  }
}

export async function getActiveSprintAndActiveuserStory(getUser, story_type, main_task_id, targetDate, taskId, assignedTo, story_title, story_us_id, project_id, project_name, meeting_url, handleClose, handleOpen, message) {
  if (story_type === 'group' && main_task_id !== '0') {
    Alert('warning', 'You can join this group task after the group creator has joined');
  } else {
    try {
      let sprintId = ''
      let sprintDesc = ''
      let sprintTargetDate = ''
      var sprintRes = await API.post("getUpdateSprint.php", {
        "crop": project_name,
        "action": "get_sprints",
        "projectId": project_id
      }, {}, false);
      if (sprintRes.status === 'True') {
        sprintId = sprintRes.data[0].moduleId
        sprintDesc = sprintRes.data[0].moduleDesc
        sprintTargetDate = sprintRes.data[0].targetDate
      }
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "sprint_update",
        sprintId: sprintId,
        userStoryId: taskId,
        targetDate: (targetDate === '') ? sprintTargetDate : targetDate,
        "projectId": project_id,
        device_id: "",
        player_id: "",
        sprintDesc: sprintDesc,
        assign_to: assignedTo,
        emp_id: getUser.empId,
        title: story_title,
        storyPoints: '0',
        story_us_id: story_us_id
      }, {}, false);
      if (response.status === 'True') {
        if (story_type === 'group' && main_task_id === '0') {
          getGroupTaskInfo(sprintTargetDate, targetDate, taskId, sprintId, getUser, assignedTo, sprintDesc, story_title, story_us_id, story_type, main_task_id, project_id, project_name, meeting_url, handleClose, handleOpen, message)
        }
        getActiveUserStory(getUser, taskId, sprintId, handleClose, handleOpen, message, project_name, meeting_url)
      }
    } catch (error) {
      Alert('error', error.message);
    }
  }
}

async function getGroupTaskInfo(sprintTargetDate, targetDate, taskId, sprintId, getUser, assignedTo, sprintDesc, story_title, story_us_id, story_type, main_task_id, project_id, project_name, meeting_url, handleClose, handleOpen, message) {
  try {
    var response = await API.post("getUpdateSprint.php", {
      story_us_id: story_us_id,
      action: "get_group_task_info",
    }, {}, false);
    if (response.status === 'True') {
      response.data.map(item => {
        return moveGroupTasks(sprintTargetDate, targetDate, item.story_id, sprintId, getUser, item.assigned_to, sprintDesc, story_title, story_us_id, story_type, main_task_id, project_id, project_name, meeting_url, handleClose, handleOpen, message)
      })
    }
  } catch (error) {
    Alert('error', error.message);
  }
}

async function moveGroupTasks(sprintTargetDate, targetDate, taskId, sprintId, getUser, assign_to, sprintDesc, story_title, story_us_id, story_type, main_task_id, project_id, project_name, meeting_url, handleClose, handleOpen, message) {
  try {
    await API.post("getUpdateSprint.php", {
      crop: project_name,
      action: "sprint_update",
      sprintId: sprintId,
      userStoryId: taskId,
      targetDate: (targetDate === '') ? sprintTargetDate : targetDate,
      "projectId": project_id,
      device_id: "",
      player_id: "",
      sprintDesc: sprintDesc,
      assign_to: assign_to,
      emp_id: getUser.empId,
      title: story_title,
      storyPoints: '0',
      story_us_id: story_us_id
    }, {}, false);
  } catch (error) {
    Alert('error', error.message);
  }
}